var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom",
        trigger: "click",
        "visible-arrow": false,
        "popper-class": "questionListPopover"
      }
    },
    [
      _c(
        "div",
        { staticClass: "questionListContent" },
        _vm._l(_vm.handlerQuestionsList, function(item, index) {
          return _c(
            "div",
            {
              key: item.questionId,
              staticClass: "questionItem",
              class: [
                item.hasSelected ? "hasSelectedStyle" : "",
                _vm.colorStyle == "#33CC66"
                  ? "hoverItem_default"
                  : "hoverItem_email"
              ],
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.selectThisQuestion(index)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(item.question) + "\n    ")]
          )
        }),
        0
      ),
      _vm._t("default", null, { slot: "reference" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }