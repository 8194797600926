<template>
  <el-popover
    placement="bottom"
    trigger="click"
    :visible-arrow="false"
    popper-class="questionListPopover"
  >
    <div class="questionListContent">
      <div
        class="questionItem"
        v-for="(item, index) in handlerQuestionsList"
        :key="item.questionId"
        @click.stop="selectThisQuestion(index)"
        :class="[
          item.hasSelected ? 'hasSelectedStyle' : '',
          colorStyle == '#33CC66' ? 'hoverItem_default' : 'hoverItem_email',
        ]"
      >
        {{ item.question }}
      </div>
    </div>
    <slot slot="reference"></slot>
  </el-popover>
</template>
<script>
export default {
  name: "questionListPopover",
  props: {
    colorStyle: {
      type: String,
      default: "#33CC66",
    },
    // 被选中的问题ID
    handlerQuestionsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 所处的下标
    selecterIndex: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    // 选择这个问题
    selectThisQuestion(index) {
      if (this.handlerQuestionsList[index].hasSelected) {
        return;
      }
      this.$emit(
        "selectQuestion",
        this.handlerQuestionsList[index],
        this.selecterIndex
      );
    },
  },
};
</script>
<style lang="stylus" scoped></style>
<style lang="stylus">
.questionListPopover
  width 368px !important;
  padding 0 !important;
  .questionListContent
    width 100%;
    height 300px;
    padding 0 20px;
    box-sizing border-box;
    overflow-x hidden;
    overflow-y auto;
    &::-webkit-scrollbar
      width 4px;
    &::-webkit-scrollbar-thumb
      border-radius: 2px;
      box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
      background: #ADADAD;
    &::-webkit-scrollbar-track
      box-shadow: inset 0 0 5px rgba(0,0,0,0);
      border-radius: 0;
      background: transparent;
    .hoverItem_email
      &:hover
        color #F39945 !important;
    .hoverItem_default
      &:hover
        color #33CC66 !important;
    .questionItem
      width 100%;
      height 46px;
      padding 0 5px;
      user-select none;
      box-sizing border-box;
      border-bottom 1px solid #F0F0F0;
      color #343434;
      font-size 14px;
      transition color .3s;
      overflow hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      line-height 46px;
      text-align left;
      cursor pointer;
      &:last-child
        border-bottom none;
    .hasSelectedStyle
      color #CCCCCC !important;
      &:hover
        cursor not-allowed !important;
        color #CCCCCC !important;
</style>
