<template>
  <div class="format" v-resize-ob="handlerResize">
    <div class="inputOuter" :style="inputOuterStyle">
      <AddressSelectorForPhone @selectThisAreaCode="selectThisAreaCode">
        <div class="positionOuter">
          <div class="positionLeft">
            <div class="positionIconBox" :style="positionIconStyle">
              <el-image
                style="width: 100%; height: 100%"
                :src="
                  require('../../../assets/images/email/loginDialog/position.png')
                "
                :fit="'cover'"
              ></el-image>
            </div>
            <div
              class="positionDescription"
              :title="positionDescription"
              :style="positionDescriptionStyle"
            >
              {{ positionDescription }}
            </div>
          </div>
          <div class="positionRight" :style="positionRightStyle">
            <span
              class="iconfont_Me icon-down iconStyle"
              :style="rightIconStyle"
            ></span>
          </div>
        </div>
      </AddressSelectorForPhone>
    </div>
    <div class="inputOuter" :style="inputOuterStyle">
      <CommonInput
        @inputChange="getNewPhoneNumberText"
        :fixedText="countryCodeForNewPhone"
        :colorStyle="colorStyle"
        :onlyType="'number'"
      >
        <el-image
          style="width: 100%;"
          :src="
            require('../../../assets/images/email/loginDialog/phone_not_selected.png')
          "
          :fit="'cover'"
        ></el-image>
      </CommonInput>
    </div>
    <div
      class="inputOuter verificationCodeBox"
      v-show="showVerificationCode"
      :style="inputOuterStyle"
    >
      <div class="leftInputBox" :style="verificationInputStyle">
        <CommonInput
          @inputChange="verificationCodeForNewPhone"
          :colorStyle="colorStyle"
          :onlyType="'number'"
          :needInputshowError="showError"
        >
          <el-image
            style="width: 100%;"
            :src="
              require('../../../assets/images/email/loginDialog/verificationCode.png')
            "
            :fit="'cover'"
          ></el-image>
        </CommonInput>
      </div>
      <div
        class="rightButtonStyle"
        :class="
          sendCodeDisable ? 'rightSendButton_disabled' : 'rightSendButton'
        "
        :style="{
          border: `1px solid ${
            sendCodeDisable ? 'rgba(0,0,0,0.1)' : colorStyle
          }`,
        }"
        @click.stop="sendVerificationCodeForNewPhone"
        :title="$t('verificationCode')"
      >
        <span
          v-if="!hasSendVerificationCode"
          class="codeBlock"
          :style="{
            color: `${sendCodeDisable ? 'rgba(0,0,0,0.1)' : colorStyle}`,
            'font-size': `${
              realTimeComputing(14) < 12 ? 12 : realTimeComputing(14)
            }px`,
          }"
        >
          {{ $t("verificationCode") }}
        </span>
        <span v-else class="countdownStyle" :style="{ color: colorStyle }"
          >{{ countdownText }}S</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { sendValidateCode } from "@/newVersion/components/accountSecurityDialog/api";
import AddressSelectorForPhone from "../addressSelector/addressSelectorForPhone.vue";
import CommonInput from "../inputComponent/commonInput.vue";
import { getIpinfo } from "@/api/newVersion/melinked_email";
import { getCountryCallingCode } from "libphonenumber-js";
export default {
  name: "VerifyPhone.vue",
  components: {
    CommonInput,
    AddressSelectorForPhone,
  },
  data() {
    return {
      // 区号
      countryCodeForNewPhone: "",
      // 区号对应的国家名称
      positionDescription: "",
      // 新手机号
      newPhoneNumberText: "",
      // 验证码
      verificationTextForNewPhone: "",
      // 已发送验证码
      hasSendVerificationCode: false,
      // 发送倒计时
      countdownText: 0,
      // 倒计时定时器对象
      countdownTimer: null,
      // 宽度监听
      widthListener: 0,
      // 基准宽度
      originalWidth: 360,
      // 验证码输入错误
      showError: false,
    };
  },
  props: {
    colorStyle: {
      type: String,
      default: "#33CC66",
    },
  },
  watch: {
    // 监听区号设置区号对应的国家名称
    countryCodeForNewPhone: {
      handler(val, old) {
        if (val !== "") {
          this.countriesData.forEach((item) => {
            let catchValue = item.children.find(
              (element) => element.sort === val
            );
            if (catchValue) {
              this.positionDescription = catchValue.label;
            }
          });
          this.$forceUpdate();
        }
      },
      deep: true,
      immediate: true,
    },
    // 监听已发送旧手机号的验证码,开始倒计时
    hasSendVerificationCode: {
      handler(val, old) {
        if (val && val !== old) {
          clearTimeout(this.countdownTimer);
          this.countdownText = 60;
          this.handleCountdown();
        }
      },
      deep: true,
    },
  },
  destroyed() {
    if (this.countdownTimer) {
      clearTimeout(this.countdownTimer);
    }
  },
  async mounted() {
    await this.getIpinfo();
  },
  computed: {
    showVerificationCode() {
      return this.newPhoneNumberText !== "";
    },
    sendCodeDisable() {
      return this.newPhoneNumberText == "" || this.hasSendVerificationCode;
    },
    // 处理过的国家数据
    countriesData() {
      return this.$store.state.homePage.sortedCountriesData;
    },
    inputOuterStyle() {
      return {
        height: `${this.realTimeComputing(46)}px`,
        "margin-bottom": `${this.realTimeComputing(18)}px`,
      };
    },
    positionIconStyle() {
      return {
        width: `${this.realTimeComputing(24)}px`,
        height: `${this.realTimeComputing(24)}px`,
        padding: `0 ${this.realTimeComputing(10)}px`,
      };
    },
    positionRightStyle() {
      return {
        width: `${this.realTimeComputing(16)}px`,
        "margin-right": `${this.realTimeComputing(18)}px`,
      };
    },
    rightIconStyle() {
      return {
        "font-size": `${this.realTimeComputing(12)}px`,
      };
    },
    positionDescriptionStyle() {
      return {
        "font-size": `${this.realTimeComputing(14)}px`,
      };
    },
    verificationInputStyle() {
      return {
        width: `${this.realTimeComputing(196)}px`,
      };
    },
  },
  methods: {
    async getIpinfo() {
      try {
        const result = await getIpinfo();
        this.countryCodeForNewPhone = getCountryCallingCode(result.country);
        this.$emit("selectThisAreaCode", this.countryCodeForNewPhone);
      } catch (e) {}
    },
    realTimeComputing(actualValue) {
      const countWidth = Math.ceil(
        (actualValue / this.originalWidth) * this.widthListener
      );
      return countWidth > actualValue ? actualValue : countWidth;
    },
    // 监听大小改变动态改变样式
    handlerResize(resize) {
      this.widthListener = resize.width;
    },
    // 选择区号
    selectThisAreaCode(areaCode) {
      this.countryCodeForNewPhone = areaCode;
      this.$emit("selectThisAreaCode", areaCode);
    },
    // 获取新手机号文本
    getNewPhoneNumberText(text) {
      this.newPhoneNumberText = text;
      this.$emit("getInputText", text);
    },
    // 获取新手机号的验证码
    verificationCodeForNewPhone(text) {
      this.verificationTextForNewPhone = text;
      this.$emit("getVerificationText", text);
    },
    async sendVerificationCodeForNewPhone() {
      if (this.newPhoneNumberText === "") {
        this.$message({
          type: "error",
          message: "The cell phone number cannot be empty",
        });
        return;
      }
      // 如果还在倒计时中，就不能再发送
      if (this.hasSendVerificationCode) {
        return;
      }
      try {
        let params = {
          countryCode: this.countryCodeForNewPhone,
          phone: this.newPhoneNumberText,
        };
        let result = await sendValidateCode(params);
        if (result.code === 200) {
          this.hasSendVerificationCode = true;
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      } catch (e) {}
    },
    // 倒计时计算操作
    handleCountdown() {
      this.countdownTimer = setTimeout(() => {
        if (this.countdownText > 1) {
          this.countdownText--;
          this.handleCountdown();
        } else {
          clearTimeout(this.countdownTimer);
          this.hasSendVerificationCode = false;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="stylus" scoped>
.format
  width 100%;
  overflow hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .inputOuter
    width 100%;
    overflow hidden;
    &:last-child
      margin-bottom 0 !important;
    .iconStyle
      color #666666;
    .positionOuter
      width 100%;
      height 100%;
      cursor pointer;
      user-select none;
      overflow hidden;
      border-radius 4px;
      box-sizing border-box;
      display flex;
      align-items center;
      justify-content space-between;
      border 1px solid #8F8F8F;
      .positionLeft
        flex 1;
        height 100%;
        min-width 0;
        display flex;
        align-items center;
        justify-content flex-start;
        .positionIconBox
          flex-shrink 0;
          display flex;
          align-items center;
          justify-content center;
          border-right: 1px solid #D9D9D9;
          box-sizing: content-box;
        .positionDescription
          flex 1;
          height 100%;
          text-align left;
          color #343434;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          padding: 0 5px;
          display: flex;
          align-items: center;
      .positionRight
        height 100%;
        flex-shrink 0;
        display flex;
        align-items center;
        justify-content center;
        .iconStyle
          color #8F8F8F;
.verificationCodeBox
  display flex;
  align-items center;
  justify-content flex-start;
  .leftInputBox
    height 100%;
    flex-shrink 0;
  .rightButtonStyle
    flex 1;
    height 100%;
    margin-left 5px;
    box-sizing border-box;
    border-radius 4px;
    user-select none;
    cursor pointer;
    display flex;
    align-items center;
    justify-content center;
    transition background .3s;
    padding: 0 10px;
    .codeBlock
      display: -webkit-box;
      -webkit-line-clamp: 2; // 设置两行文字溢出
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
  .rightSendButton
    &:hover
      background #F2FCF8;
  .rightSendButton_disabled
    cursor not-allowed;
    &:hover
      background transparent !important;
    .countdownStyle
      font-size 14px;
</style>
